<template>
  <div>
    <div class="tnav">
      <div class="tnav-l">
        <div class="tnav-back" @click="tourl(backurl,query)"><span class="iconfont">&#xe7ec;</span>返回</div>
      </div>
      <div class="tnav-c">{{title||''}}</div>
      <div class="tnav-r"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Nav',
  props: {
    backurl:{
      type:String,
      default:'/'
    },
    query:{
      type:Object,
    },
    title:{
      type:String,
      default: ''
    }
  }
}
</script>
<style lang="less">
.tnav{
  display: flex; height: 8.1rem; width: 100%; justify-content: center;
  .tnav-l{
    height: 5.6rem; width: 10rem; flex-grow: 1; background-image: linear-gradient(to right,rgba(0, 157, 255, 0.16),#101220);
    .tnav-back{
      width: 8.4rem; height: 3.2rem; margin:1.2rem 0 0 3.2rem; font-size: 1.6rem; line-height: 3rem; color: #009DFF; text-align: center; border-radius: 0.4rem; border:0.1rem solid #009DFF; box-sizing: border-box; cursor: pointer;
      span{display: inline-block; padding: 0 0.8rem 0 0;}
    }
  }
  .tnav-c{width: 64rem; height: 8.1rem;font-size: 3.6rem; font-weight: bold; line-height: 8rem; color: #FFFFFF; text-align: center; overflow: hidden; background: url("~@/assets/images/nav-txt-bg.png") center top no-repeat; background-size: cover;}
  .tnav-r{height: 5.6rem; width: 10rem; flex-grow: 1; background-image: linear-gradient(to right,#101220,rgba(0, 157, 255, 0.16));}
}
</style>
