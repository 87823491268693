import Vue from 'vue'
import App from './App.vue'
import router from './router'
import baiduMap from 'vue-baidu-map'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import axios from 'axios'
Vue.config.productionTip = false
Vue.use(ViewUI);
Vue.use(baiduMap, {
  ak: 'QHHIihvIp6dzWgULHe89cbrU'
})
Vue.prototype.api_url='https://api.yizhenk.com';
Vue.prototype.tourl = function (url, query) {
  const defaultQuery = {};
  const queryObj = {
    ...defaultQuery,
    ...query
  };
  this.$router.push({ path: url, query: queryObj })
};
Vue.prototype.requestUrl=function (url,opt,fun){
  url=this.api_url+url;
  let data={};
  if(typeof opt =="object") data=opt;
  axios({
    method:'post',
    url:url,
    data: data,
    headers:{'content-Type': 'application/x-www-form-urlencoded'}
  }).then(function(res) {
    const result=res.status == 200?res.data:{status:res.status,msg:res};
    if(typeof opt == "function") opt(result);
    else fun(result)
  }).catch(function (error) {
    const err={status:400,msg:error};
    if(typeof opt == "function") opt(err);
    else fun(err);
  });
}
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')