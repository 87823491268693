import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Notice from '../views/Notice.vue'
import NoticeDetail from '../views/NoticeDetail.vue'
import Album from '../views/Album.vue'
import AlbumList from '../views/AlbumList.vue'
import BigData from '../views/BigData.vue'
import Video from '../views/Video.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component:About
  },
  {
    path: '/notice',
    name: 'Notice',
    component:Notice
  },
  {
    path: '/notice_detail',
    name: 'NoticeDetail',
    component:NoticeDetail
  },
  {
    path: '/album',
    name: 'Album',
    component:Album
  },
  {
    path: '/album_list',
    name: 'AlbumList',
    component:AlbumList
  },
  {
    path: '/big_data',
    name: 'BigData',
    component:BigData
  },
  {
    path: '/video',
    name: 'Video',
    component:Video
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
