<template>
  <div class="about">
    <Nav title="关于益诊康"></Nav>

    <div class="cmanw">
      <div class="acontent" v-if="show_content==1">
        <div class="about-banner"><img src="~@/assets/testimg/about-l-01.jpg" /></div>
        <div class="compw">
          <div class="compname">广东益诊康网络科技有限公司</div>
          <div class="com-txt">
            <p>定位：打造中国互联网+智能诊所服务平台</p>
            <p>广东益诊康网络科技有限公司是一家互联网医药健康领域的驱动企业，致力于用创新的互联网和大数据技术，提供在线诊疗、购药和健康管理等服务，缩减中间环节、优化供应链，为中国百姓的就医买药提供完整的解决方案。</p>
            <p>益诊康以“医+药”为切入点，为诊所和单体药店提供“益诊康APP”系统，不断为诊所和单体药店的医生做产品赋能、技术赋能、运营赋能、智能赋能，解决诊所和单体药店日常采购、运营、服务和品牌等问题。公司的B2B医药平台、B2C附近医生、O2O药房平台，打通了大健康网上商城B2B、B2C、O2O的互联网生态圈。</p>
          </div>
        </div>
        <div class="horn-tl"></div>
        <div class="horn-tr"></div>
        <div class="horn-bl"></div>
        <div class="horn-br"></div>
      </div>
      <div class="acontent" v-if="show_content==2">
        <div class="culw">
          <div class="cul-tit">专业+经验+创意+服务=专业的技术,丰富的成功经验,卓越的创作思维,为您创作出一流的品牌互联网形象！</div>
          <div class="cul-txt">广东益诊康科技有限公司服务团队是一支充满着热情、执着、敏锐和追求的团队，为客户提供尽善尽美的服务是我们的理念。一个人能走多远，取决于与谁同行，庆丰裕信息科技团队是一个富有理想和激情的团队，是一个蓬勃向上并富有朝气的团队，也是一个技术专业化、管理人性化、创新性和学习型的优秀团队。</div>
          <img class="cul-banner" src="~@/assets/testimg/cul-banner.jpg" />
        </div>
        <div class="curw">
          <div class="cu-ls">
            <div class="cu-ls-img"><img src="~@/assets/testimg/cul-r1.jpg" /></div>
            <div class="cu-ls-des">
              <div class="des-vcenter">
                <div class="cur-tit">我们的愿景</div>
                <div class="cur-txt">成为最受客户信任的互联网企业。</div>
              </div>
            </div>
          </div>
          <div class="cu-ls">
            <div class="cu-ls-img"><img src="~@/assets/testimg/cul-r2.jpg" /></div>
            <div class="cu-ls-des">
              <div class="des-vcenter">
                <div class="cur-tit">我们的使命</div>
                <div class="cur-txt">提升企业品牌形象，以高品质的服务、 稳健的技术、 向用户提供丰富、可靠的互联网产品持续关注新的互联网走向、 积极探索新的用户需求、不断为用户提供创新的业务为企业搭建优秀的网络平台、捕捉有效的客户信息、 促进企业经济的快速发展 。 </div>
              </div>
            </div>
          </div>
          <div class="cu-ls">
            <div class="cu-ls-img"><img src="~@/assets/testimg/cul-r3.jpg" /></div>
            <div class="cu-ls-des">
              <div class="des-vcenter">
                <div class="cur-tit">我们的价值观</div>
                <div class="cur-txt">诚信、专注、尽责、创新。。</div>
              </div>
            </div>
          </div>
        </div>
        <div class="horn-tl"></div>
        <div class="horn-tr"></div>
        <div class="horn-bl"></div>
        <div class="horn-br"></div>
      </div>
      <div class="acontent" v-if="show_content==3">
        <div class="cousw">
          <baidu-map :center="center" :zoom="zoom" @ready="handler" :scroll-wheel-zoom='false' :mapClick="false" class="map-img"></baidu-map>
          <div class="cous-con">
            <div class="com-name">广东益诊康网络科技有限公司</div>
            <div class="colis">
              <div class="cllis">电话：0752-3255722</div>
              <div class="cllis">邮箱：594483385@qq.com</div>
            </div>
            <div class="colis">
              <div class="cllis">微信：yizhenkang888</div>
              <div class="cllis">地址：广东省惠州市惠阳区淡水人民四路33号</div>
            </div>
            <div class="colis">
              <div class="cllis">邮编：516000</div>
              <div class="cllis"></div>
            </div>
          </div>
        </div>
        <div class="horn-tl"></div>
        <div class="horn-tr"></div>
        <div class="horn-bl"></div>
        <div class="horn-br"></div>
      </div>
    </div>

    <div class="bnavw">
      <div class="bnav-btn" :class="[show_content==1?'onhover':'']" @click="change_content(1)">企业介绍</div>
      <div class="bnav-btn" :class="[show_content==2?'onhover':'']" @click="change_content(2)">企业文化</div>
      <div class="bnav-btn" :class="[show_content==3?'onhover':'']" @click="change_content(3)">联系我们</div>
    </div>
  </div>
</template>
<script>
import Nav from '@/components/Nav'
export default {
  name: 'About',
  components: {Nav},
  data(){
    return {
      show_content:1,
      center: {lng: 114.465176, lat: 22.798545},
      zoom: 19
    }
  },
  methods:{
    change_content:function (id){
      if(this.show_content==id) return false;
      this.show_content=id;
    },
    handler ({BMap, map}) {
      var point = new BMap.Point(114.465176, 22.798545);
      map.centerAndZoom(point, 19);
      var myIcon = new BMap.Icon("/map-point.png", new BMap.Size(44, 60));
      var marker = new BMap.Marker(point,{
        icon: myIcon
      }) // 创建标注
      map.addOverlay(marker) // 将标注添加到地图中
    },
  }
}
</script>
<style lang="less">
@import '~@/assets/css/about';
</style>
