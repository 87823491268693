<template>
  <div class="notice">
    <Nav title="益诊康公告"></Nav>
    <div class="notlw">
      <div class="notmid">
        <div class="notlis" @click="todetail(p.id)" v-for="(p,key) in data.data" :key="key">
          <div class="notit">{{p.title||''}}</div>
          <div class="nodate">{{p.add_time||''}}</div>
        </div>
      </div>
    </div>
    <Page class-name="yzkpage" :total="data.total" :page-size="10" :current="current" @on-change="page" />
  </div>
</template>
<script>
import Nav from '@/components/Nav'
export default {
  name: 'Notice',
  components: {Nav},
  data(){
    return {
      data:{total:0,data:[]},
      current:1
    }
  },
  created(){
    const page=parseInt(this.$route.query.page);
    if(!isNaN(page)) this.current=page;
    this.init();
  },
  methods:{
    /**
     * 请求数据
     */
    init(){
      var _this=this;
      this.requestUrl('/notice_list',{page:this.current},function (res){
        if(res.status==1){
          _this.data=res.data;
        }
      })
    },
    /**
     * 翻页
     */
    page(page){
      this.current=page;
      this.init();
    },
    /**
     * 进入详情页
     * @param id
     */
    todetail:function (id){
      this.tourl('/notice_detail',{id:id,page:this.current});
    }
  }
}
</script>
<style lang="less">
@import '~@/assets/css/notice';
</style>
