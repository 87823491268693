<template>
    <div ref="echart" class="echarts-b">
      <div class="ech-l">
        <div class="ech-tit">{{datas.data1.title||''}}</div>
        <div class="ech-map" ref="echl"></div>
      </div>
      <div class="ech-r">
        <div class="ech-tit">{{datas.data2.title||''}}</div>
        <div class="ech-map" ref="echr"></div>
      </div>
    </div>
</template>
<script>
var echarts = require('echarts');
export default {
  name: 'Echartb',
  props: {
    datas: {
      type: Object,
      required: true
    },
    className:{
      type:String,
      defalut:''
    }
  },
  data(){
    return {
      LH:parseInt(document.documentElement.clientWidth/1920*32)
    }
  },
  mounted() {
    this.initEchartMap();
  },
  watch:{
    datas(){
      this.initEchartMap();
    }
  },
  methods:{
    initEchartMap(){
      const option1 = {
        color:['#f3931d','#38b560','#0056ff','#a526a6'],
        legend: {
          orient: 'horizontal',
          bottom: 15,
          itemWidth:16,
          itemHeight:16,
          textStyle:{
            color:'#ffffff',
            fontSize:'1.4rem',
            lineHeight:16
          }
        },
        series: [
          {
            name: this.datas.data1.title,
            type: 'pie',
            center: ['50%', '43%'],
            radius: ['50%', '70%'],
            selectedMode:'single',
            selectedOffset:5,
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              focus:'self',
              label: {
                show: true,
                fontSize: '2.4rem',
                color:'#ffffff',
                fontWeight: 'bold',
                lineHeight:this.LH,
                formatter: '{d}%\n{b}'
              }
            },
            select: {
              label: {
                show: true,
                fontSize: '2.4rem',
                color:'#ffffff',
                fontWeight: 'bold',
                lineHeight:this.LH,
                formatter: '{d}%\n{b}'
              }
            },
            labelLine: {
              show: false
            },
            data:this.datas.data1.data
          }
        ]
      };
      const myChart = echarts.init(this.$refs.echl);
      myChart.setOption(option1, true);
      const option2 = {
        color:['#f3931d','#38b560','#0056ff','#a526a6'],
        legend: {
          orient: 'horizontal',
          bottom: 15,
          itemWidth:16,
          itemHeight:16,
          textStyle:{
            color:'#ffffff',
            fontSize:'1.4rem',
            lineHeight:16
          }
        },
        series: [
          {
            name: this.datas.data2.title,
            type: 'pie',
            center: ['50%', '43%'],
            radius: ['50%', '70%'],
            selectedMode:'single',
            selectedOffset:5,
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              focus:'self',
              label: {
                show: true,
                fontSize: '2.4rem',
                color:'#ffffff',
                fontWeight: 'bold',
                lineHeight:this.LH,
                formatter: '{d}%\n{b}'
              }
            },
            select: {
              label: {
                show: true,
                fontSize: '2.4rem',
                color:'#ffffff',
                fontWeight: 'bold',
                lineHeight:this.LH,
                formatter: '{d}%\n{b}'
              }
            },
            labelLine: {
              show: false
            },
            data: this.datas.data2.data
          }
        ]
      };
      const myChart2 = echarts.init(this.$refs.echr);
      myChart2.setOption(option2, true)
    }
  }
}
</script>
<style lang="less">
.echarts-b{
  width: 100%; height: 100%;
  .ech-l{
    float: left; width: 48%; height: 100%;
  }
  .ech-r{
    float:right; width: 48%; height: 100%;
  }
  .ech-map{width: 100%; height: calc(100% - 5.6rem);}
  .ech-tit{
    height: 5.6rem; font-size: 2.4rem; font-weight: bold; line-height: 5.6rem; color: #07BAFF; text-align: center;
  }
}
</style>
