<template>
  <div class="bigdata">
    <Nav title="益诊康可视化"></Nav>
    <div class="data-out">
      <div class="data-l">
        <div class="ltopw">
          <div class="bigtitle"><span class="titbg"></span>个人销量排行<span class="titbg titbg-r"></span></div>
          <div class="memlis" v-for="(p,key) in memberlist" :key="key" :class="{'memlis0':key%2}"><span>top{{key+1}}</span>{{p.uname||''}}</div>
          <div class="horn-tl"></div>
          <div class="horn-tr"></div>
          <div class="horn-bl"></div>
          <div class="horn-br"></div>
        </div>
        <div class="lbotw">
          <div class="bigtitle"><span class="titbg"></span>区域排行<span class="titbg titbg-r"></span></div>
          <div class="arealis" v-for="(p,key) in arealist.slice(0,6)" :key="key" :class="{'arealis0':key%2}"><span>top{{key+1}}</span>{{p.name||''}}</div>
          <div class="horn-tl"></div>
          <div class="horn-tr"></div>
          <div class="horn-bl"></div>
          <div class="horn-br"></div>
        </div>
      </div>
      <div class="data-c">
        <Map :datas="datas" :select="select" className="echarts"></Map>
        <div class="graphw">
          <Echartb :datas="bdata" v-if="loaded"></Echartb>
          <div class="horn-tl"></div>
          <div class="horn-tr"></div>
          <div class="horn-bl"></div>
          <div class="horn-br"></div>
        </div>
      </div>
      <div class="data-r">
        <div class="bigtitle"><span class="titbg"></span>产品销量排行<span class="titbg titbg-r"></span></div>
        <div class="prodlis" v-for="(p,key) in prodlist" :key="key" :class="{'prodlis0':key%2}"><span>top{{key+1}}</span>{{p.goodsname||''}}{{p.maker?'（'+p.maker+'）':''}}</div>
        <div class="horn-tl"></div>
        <div class="horn-tr"></div>
        <div class="horn-bl"></div>
        <div class="horn-br"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from '@/components/Nav'
import Map from '@/components/Map'
import Echartb from '@/components/Echartb'
export default {
  name: 'BigData',
  components: {Nav,Map,Echartb},
  data(){
    return {
      select:'china',
      memberlist:[{},{},{},{},{},{},{},{},{},{},{}],//加入初始空数据 防止页面样式跳动
      arealist:[{},{},{},{},{},{}],
      docarea:[],
      prodlist:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      datas:[
          [{name:'惠州',value:0},{name:'惠州'}]
      ],
      bdata:{
        data1: {
          title:'区域销售占比',
          data:[]
        },
        data2: {
          title:'用户区域分布',
          data:[]
        }
      },
      loaded:false
    }
  },
  created() {
    this.init();
    setInterval(this.init,600000);
  },
  methods:{
    init(){
      var _this=this;
      this.requestUrl('/big_data',function (res){
        if(res.status==1){
          _this.memberlist=res.memberlist;
          _this.arealist=res.arealist;
          _this.docarea=res.docarea;
          _this.prodlist=res.prodlist;
          _this.datas=[];
          _this.bdata.data2.data=[];
          for(let k in res.docarea){
            _this.datas.push([{name:res.docarea[k].name,value: res.docarea[k].percentage},{name:'惠州'}]);
            if(_this.bdata.data2.data.length<4) _this.bdata.data2.data.push({name:res.docarea[k].name, value: res.docarea[k].tot});
          }
          _this.bdata.data1.data=[];
          for(let k in res.arealist){
            if(_this.bdata.data1.data.length<4) _this.bdata.data1.data.push({name:res.arealist[k].name, value: res.arealist[k].money});
          }
          _this.$nextTick(function (){
            _this.loaded=true;
          });
        }
      });
    }
  }
}
</script>
<style lang="less">
@import '~@/assets/css/big_data';
</style>
