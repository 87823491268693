<template>
  <div class="noticed">
    <Nav title="益诊康公告" backurl="/notice" :query="backquery"></Nav>
    <div class="noticed-con">
      <div class="notice-tiw">
        <div class="ntitle">{{data.title||''}}</div>
        <div class="ndate">发表于 {{data.add_time||''}}</div>
      </div>
      <div class="subline"></div>
      <div class="not-content" v-html="data.body"></div>
      <div class="horn-tl"></div>
      <div class="horn-tr"></div>
      <div class="horn-bl"></div>
      <div class="horn-br"></div>
    </div>
  </div>
</template>
<script>
import Nav from '@/components/Nav'
export default {
  name: 'NoticeDetail',
  components: {Nav},
  data(){
    return {
      data:{},
      id:'',
      page:1,
      backquery:{}
    }
  },
  created(){
    this.id=this.$route.query.id;
    this.page=this.$route.query.page;
    this.backquery={page:this.page};
    this.init();
  },
  methods:{
    init:function (){
      var _this=this;
      this.requestUrl('/notice_detail',{id:this.id},function (res){
        if(res.status==1){
          _this.data=res.data;
        }
      })
    }
  }
}
</script>
<style lang="less">
@import '~@/assets/css/notice_detail';
</style>
