<template>
  <div>
    <div ref="echart" :class="className"></div>
  </div>
</template>
<script>
var echarts = require('echarts');
import 'echarts/dist/china.js'
export default {
  name: 'Mpa',
  props: {
    select: {
      type: String,
      default:'china'
    },
    datas: {
      type: Array,
      required: true
    },
    className:{
      type:String,
      defalut:''
    }
  },
  data(){
    return {
      geoCoordMap: {
        '惠州': [114.6204,23.1647],
        '黑龙江': [127.9688, 45.368],
        '内蒙古': [110.3467, 41.4899],
        '吉林': [125.8154, 44.2584],
        '辽宁': [123.1238, 42.1216],
        '河北': [114.4995, 38.1006],
        '天津': [117.4219, 39.4189],
        '山西': [112.3352, 37.9413],
        '陕西': [109.1162, 34.2004],
        '甘肃': [103.5901, 36.3043],
        '宁夏': [106.3586, 38.1775],
        '青海': [101.4038, 36.8207],
        '新疆': [87.9236, 43.5883],
        '西藏': [91.11, 29.97],
        '北京': [116.4551, 40.2539],
        '四川': [103.9526, 30.7617],
        '重庆': [108.384366, 30.439702],
        '山东': [117.1582, 36.8701],
        '河南': [113.4668, 34.6234],
        '江苏': [118.8062, 31.9208],
        '安徽': [117.29, 32.0581],
        '湖北': [114.3896, 30.6628],
        '浙江': [119.5313, 29.8773],
        '福建': [119.4543, 25.9222],
        '江西': [116.0046, 28.6633],
        '湖南': [113.0823, 28.2568],
        '贵州': [106.6992, 26.7682],
        '云南': [102.9199, 25.4663],
        '广东': [113.12244, 23.009505],
        '广西': [108.479, 23.1152],
        '海南': [110.3893, 19.8516],
        '上海': [121.4648, 31.2891],
        '香港': [114.12, 22.26],
        '澳门': [113.33, 22.11],
        '台湾': [119.1803, 20.4525],
      }
    }
  },
  mounted() {
    this.initEchartMap();
  },
  watch:{
    datas(){
      this.initEchartMap();
    }
  },
  methods:{
    initEchartMap(){
      const self = this
      const convertData = function (data) {
        const res = []
        for (let i = 0; i < data.length; i++) {
          const dataItem = data[i]
          const fromCoord = self.geoCoordMap[dataItem[1].name] // 迁出点
          const toCoord = self.geoCoordMap[dataItem[0].name] // 迁入点
          if (fromCoord && toCoord) {
            res.push([{
              coord: fromCoord,
              value: dataItem[0].value
            }, {
              coord: toCoord
            }])
          }
        }
        return res
      }
      const series = [];
      // 迁入地特殊显示//广东
      [[`${self.datas[0][1].name}`, self.datas]].forEach(function (item) {
        series.push({
              type: 'lines',
              zlevel: 2,
              symbol: ['none', 'arrow'],
              symbolSize: 10,
              effect: {
                show: true,
                period: 9,// 箭头指向速度，值越小速度越快
                trailLength: 0.03,// 特效尾迹长度[0,1]值越大，尾迹越长重
                color: '#fff',
                symbolSize:8
              },
              lineStyle: {
                normal: {
                  color:'#fff',
                  width: 1.5, // 尾迹线条宽度
                  opacity: 0.4,
                  type:'solid',
                  curveness: 0.2 // 尾迹线条曲直度
                }
              },
              data: convertData(item[1])
            }, {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              zlevel: 2,
              rippleEffect: { // 涟漪特效
                period: 3, // 动画时间，值越小速度越快
                brushType: 'stroke', // 波纹绘制方式 stroke, fill
                scale: 12 // 波纹圆环最大限制，值越大波纹越大
              },
              symbol: 'circle',
              symbolSize (val) {
                return 3 + val[2]/10;
              },
              itemStyle: {
                normal: {
                  show: false,
                  color: '#f00'
                }
              },
              data: item[1].map(function (dataItem) {
                return {
                  name: dataItem[0].name,
                  value: self.geoCoordMap[dataItem[0].name].concat([dataItem[0].value])
                }
              })
            },
            // 迁入点
            {
              type: 'scatter',
              coordinateSystem: 'geo',
              zlevel: 2,
              rippleEffect: {
                period: 4,
                brushType: 'stroke',
                scale: 4
              },
/*              label: {
                normal: {
                  show: true,
                  position: 'right',
                  offset: [5, 0],
                  color: '#0f0',
                  formatter: '{b}',
                  textStyle: {
                    color: '#0f0'
                  }
                },
                emphasis: {
                  show: true,
                  color: '#f60'
                }
              },*/
              symbol: 'circle',
              symbolSize: 10,
              data: [{
                name: item[0],
                value: self.geoCoordMap[item[0]].concat([10])
              }]
            }
        )
      })
      const option = {
        /*tooltip: {
          trigger: 'item',
          formatter (params) {
            // 根据业务自己拓展要显示的内容
            let res = ''
            const name = params.name
            const value = params.value[params.seriesIndex + 1]
            if (value !== undefined) {
              res = "<span style='color:#fff;'>" + name + '</span><br/>游客人数：' + value
              return res
            }
          }
        },*/
        backgroundColor: 'rgba(31, 35, 43, 0)',
        visualMap: { // 图例值控制
          min:0,
          max:13,
          show: false,
          color: ['red', 'orange', 'yellow','lime','aqua'],
        },
        geo: {
          map: `${self.select}`,
          zoom: 1.2,
          label: {
            emphasis: {
              show: false
            }
          },
          roam: false, // 是否允许缩放
          itemStyle: {
            normal: {
              borderColor: 'rgba(30,144,255,0.5)',
              borderWidth: 2,
              areaColor: '#00013A'
            },
            emphasis: {
              areaColor: '#389BB7',
              borderWidth: 0
            }
          }
        },
        series
      }
      const myChart = echarts.init(this.$refs.echart);
      myChart.setOption(option, true)
    }
  }
}
</script>
<style lang="less">
</style>
