<template>
  <div class="albumlist">
    <Nav title="公司相册" backurl="/album"></Nav>
    <div class="list-out">
      <div class="piclist" v-for="(p,key) in imglist" :key="key" :style="{backgroundImage:(p.loaded?'url('+webUrl+p.img+')':'url('+defimg+')')}" @click="show_original(key)"></div>
    </div>
    <div v-if="originalsrc" class="original-img" :class="{'closeoriginal':closeoriginal}" @click="close_original"><img :src="webUrl+originalsrc" /></div>
  </div>
</template>
<script>
import Nav from '@/components/Nav'
export default {
  name: 'Album',
  components: {Nav},
  data(){
    return {
      defimg:require('@/assets/images/default-img.png'),
      imglist:[],
      originalsrc:'',
      closeoriginal:false,
      webUrl:this.api_url
    }
  },
  mounted() {
    this.load_img();
  },
  created() {
     this.init();
  },
  methods:{
    /**
     * 初始化
     */
    init(){
      var _this = this;
      this.requestUrl('/webImgList',function(res){
        if(res.status){
          _this.imglist = res.data;
        }
      })
    },
    /**
     * 点击查看大图
     */
    show_original(index){
      this.closeoriginal=false;
      this.originalsrc=this.imglist[index].img
    },

    /**
     * 关闭大图
     */
    close_original(){
      this.closeoriginal=true;
    },
    /**
     * 加载图片
     */
    load_img(){
      var _this=this;
      for (let k in this.imglist){
        let img=new Image();
        img.src=_this.imglist[k].img;
        img.onload=function(){
          _this.imglist[k].loaded=true;
        };
      }
    }
  }
}
</script>
<style lang="less">
@import '~@/assets/css/album_list';
</style>
