<template>
  <div class="video">
    <Nav title="益诊康视频"></Nav>
    <div class="video-out">
      <div class="vcontent">
        <div class="videolis" v-for="(p,key) in data" :key="key" @click="open_video(key)">
          <div class="video-img"><img :src="webUrl+p.thumb" /><div class="video-ico"></div></div>
          <div class="video-title">{{p.title}}</div>
          <div class="video-date">发布于 {{p.add_time}}</div>
        </div>
      </div>
    </div>
    <Page class-name="yzkpage" :total="data.total" :page-size="9" :current="page" @on-change="changePage" />
    <div class="show_video" v-if="playdata.status"><video class="videop" :src="playdata.videosrc" controls="controls" autoplay="autoplay" loop="loop" controlsList="nodownload"></video><div class="close-video iconfont" @click="close_video">&#xe7fd;</div></div>
  </div>
</template>
<script>
import Nav from '@/components/Nav'
export default {
  name: 'Video',
  components: {Nav},
  data(){
    return {
      page:1,
      total:0,
      data:[],
      playdata:{
        videosrc:'',
        status:false
      },
      webUrl:this.api_url
    }
  },
  created() {
    this.init();
  },
  methods:{
    /**
     * 初始化
     */
    init(){
      var _this = this;
      this.requestUrl('/video_list',function (res) {
        if(res.status){
          _this.data  = res.data.data;
          _this.total = res.data.total;
        }
      });
    },
    /**
     * 播放视频
     */
    open_video(key){
      this.playdata.videosrc=this.webUrl+this.data[key].video;
      this.playdata.status=true;
    },
    /**
     *关闭视频
     */
    close_video(){
      this.playdata.status=false;
      this.playdata.videosrc='';
    },
    /**
     * 翻页
     */
    changePage(page){
      this.page=page;
    },
  }
}
</script>
<style lang="less">
@import '~@/assets/css/video';
</style>
