<template>
  <div class="album">
    <Nav title="公司相册"></Nav>
    <swiper ref="swiperTop" :options="swiperOptions" class="tswiper" @slideChange="swipe_big" @doubleTap="show_original">
      <swiper-slide v-for="(p,key) in imglist" :style="{backgroundImage:(p.loaded?'url('+webUrl+p.img+')':'url('+defimg+')')}" :key="key" data=""></swiper-slide>
    </swiper>
    <div class="thumbw">
      <swiper ref="swiperThumbs" :options="swiperOptionsThumbs" class="bswiper" @slideChange="swipe_thumb" @click="change_item">
        <swiper-slide v-for="(p,key) in imglist" :style="{backgroundImage:(p.loaded?'url('+webUrl+p.img+')':'url('+defimg+')')}" :key="key" :class="{'activeth':activeIndex==key}"></swiper-slide>
      </swiper>
      <div class="all-btn" @click="tourl('/album_list')"><span class="iconfont">&#xe632;</span>查看全部</div>
    </div>
    <div v-if="originalsrc" class="original-img" :class="{'closeoriginal':closeoriginal}" @click="close_original"><img :src="webUrl+originalsrc" /></div>
  </div>
</template>
<script>
import Nav from '@/components/Nav'
import {Swiper,SwiperSlide } from 'vue-awesome-swiper'
import 'vue-awesome-swiper/dist/swiper.min.css'
export default {
  name: 'Album',
  components: {Nav,Swiper,SwiperSlide},
  data(){
    return {
      defimg:require('@/assets/images/default-img.png'),
      webUrl:this.api_url,
      imglist:[],
      activeIndex:0,
      originalsrc:'',
      closeoriginal:false,
      swiperOptions:{
        spaceBetween: 24,
        slidesPerView: 1.8,
        centeredSlides:true,
        grabCursor:true,
        preloadImages:true //预加载所有图片  再初始化swiper
      },
      swiperOptionsThumbs:{
        spaceBetween: 24,
        slidesPerView: 6,
        grabCursor:true,
        preloadImages:false
      }
    }
  },
  mounted(){
    this.load_img();
  },
  created(){
    this.init();
  },
  methods:{
	/**
	 * 初始化
	 */
    init(){
      var _this = this;
      this.requestUrl('/webImgList',function(res){
        if(res.status){
          _this.imglist = res.data;
        }
      })
    },
    /**
     * 大轮播图 切换
     */
    swipe_big(swiper){
      this.activeIndex=swiper.activeIndex;
      this.$refs.swiperThumbs.$swiper.slideTo(swiper.activeIndex, 500, false)
    },
    /**
     * 缩略图 切换
     */
    swipe_thumb(swiper){
      this.$refs.swiperTop.$swiper.slideTo(swiper.activeIndex, 500, false)
    },
    /**
     * 点击缩略图
     */
    change_item(swiper){
      this.activeIndex=swiper.clickedIndex;
      this.$refs.swiperThumbs.$swiper.slideTo(swiper.clickedIndex, 500, false)
      this.$refs.swiperTop.$swiper.slideTo(swiper.clickedIndex, 500, false)
    },
    /**
     * 双击查看大图
     */
    show_original(swiper){
      this.closeoriginal=false;
      this.originalsrc=this.imglist[swiper.clickedIndex].img
    },

    /**
     * 关闭大图
     */
    close_original(){
      this.closeoriginal=true;
    },
    /**
     * 加载图片
     */
    load_img(){
      var _this=this;
      for (let k in this.imglist){
        let img=new Image();
        img.src=_this.imglist[k].img;
        img.onload=function(){
          _this.imglist[k].loaded=true;
        };
      }
    }
  }
}
</script>
<style lang="less">
@import '~@/assets/css/album';
</style>
