<template>
  <div class="home">
    <div class="nav-w">
      <div class="top1">
        <div class="bigdata" @click="tourl('/big_data')">
          <img src="~@/assets/images/nav-01.png" class="nav-ico" />
          <span class="nav-txt">大数据可视化</span>
        </div>
        <div class="aboutus" @click="tourl('/about')">
          <img src="~@/assets/images/nav-02.png" class="nav-ico" />
          <span class="nav-txt">关于我们</span>
        </div>
      </div>
      <div class="top2">
        <div class="album" @click="tourl('/album')">
          <img src="~@/assets/images/nav-03.png" class="nav-ico" />
          <span class="nav-txt">公司相册</span>
        </div>
        <div class="video" @click="tourl('/video')">
          <img src="~@/assets/images/nav-04.png" class="nav-ico" />
          <span class="nav-txt">视频</span>
        </div>
        <div class="notices" @click="tourl('/notice')">
          <img src="~@/assets/images/nav-05.png" class="nav-ico" />
          <span class="nav-txt">公司公告</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {}
}
</script>
<style lang="less">
@import '~@/assets/css/home';
</style>
